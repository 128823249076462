<template>
  <div>
    <div class="page-title">外延今日出库</div>
    <!-- 主表格 -->
    <div style="float:left;width:100%;padding-top:1rem">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="outList"
        border
        ref="multipleTable"
        height="600"
        :cell-style="{padding:0,height:'28px','text-align':'center'}"
        :row-height="28"
        :header-cell-style="{padding:0,height:'30px'}"
        @selection-change="RowSelect"
      >
        <pl-table-column type="selection" width="40"></pl-table-column>
        <pl-table-column prop="出库日期" label="出库日期" width="100"></pl-table-column>
        <pl-table-column prop="生产类型" label="生产类型" width="80"></pl-table-column>
        <pl-table-column prop="结构" label="结构" width="80"></pl-table-column>
        <pl-table-column prop="衬底尺寸" label="衬底尺寸" width="80"></pl-table-column>
        <pl-table-column prop="波段" label="波段" width="80"></pl-table-column>
        <pl-table-column prop="版型" label="版型" width="80"></pl-table-column>
        <pl-table-column prop="出库流向" label="出库流向" width="80"></pl-table-column>
        <pl-table-column prop="小计" label="小计" width="70"></pl-table-column>
        <pl-table-column label="操作" width="100">
          <template slot-scope="s">
            <el-button type="warning" plain size="mini" @click="ShowListInfo(s.$index)">明细</el-button>
          </template>
        </pl-table-column>
      </pl-table>
      <el-button style="margin-top:1rem" @click="Output">导出今日出库明细</el-button>
    </div>
    <!-- 清单明细弹窗 -->
    <el-dialog
      title="外延今日出库明细"
      :visible.sync="infoLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 表格 -->
      <ex-table :table-data="listInfo"/>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        共 {{ listInfo.length }} 条记录
        <el-button @click="infoLayer=false" type="danger" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getEpiOutToday} from "@/http/api";
import ExTable from "@/components/ExTable";
import {formRequest} from "@/http/formRequest";
import {baseUrl} from "@/configs/squareConfig";

export default {
  name: "ProdEpiInToday",
  components: {ExTable},
  data() {
    return {
      outList: [],
      selected: [],
      infoLayer: false,
      listInfo: [],
    }
  },
  methods: {
    GetEpiOut() {
      getEpiOutToday({token: this.$cookies.get('token')})
        .then(res => this.outList = res.data.rs)
        .catch(err => console.log(err))
    },
    RowSelect(s) {
      this.selected = s
    },
    ShowListInfo(idx) {
      this.listInfo = JSON.parse(this.outList[idx].明细)
      this.infoLayer = true
    },
    Output() {
      formRequest(baseUrl + '/ex-out-today-output', {token: this.$cookies.get('token')})
    },
  },
  activated() {
    this.GetEpiOut()
  }
}
</script>

<style scoped>

</style>
